.rsvp-card {
  max-width: 800px;
  margin: 0 auto 50px auto;
  padding: 20px 10px;
  background-color: var(--color-green-1);
  border-radius: 5px;
}

.rsvp-card .invitation-name, .rsvp-card h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Italiana';
}

.rsvp-card .attend-question {
  text-align: center;
}

.rsvp-card .attending-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.rsvp-card button.selected {
  pointer-events: none;
}

.rsvp-card button.not-selected {
  filter: grayscale(1);
  opacity: 0.8;
}

.rsvp-card button.not-selected:hover {
  filter: none;
}

.rsvp-card .not-attending-message {
  text-align: center;
  font-family: 'Italiana';
  font-weight: bold;
  font-size: 20px;
}

.rsvp-card .rsvp-count-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.rsvp-card .meals-question {
  border-top: 1px solid var(--color-dark);
  padding-top: 20px;
}

.rsvp-card .meals-table {
  margin: 20px 0;
}

.rsvp-card .meals-table td {
  padding: 5px;
}

.rsvp-card .menu h3 {
  border-bottom: 1px solid var(--color-dark);
}

.rsvp-card .menu p {
  margin-bottom: 30px;
}

.song-request-form {
  border-top: 1px solid var(--color-dark);
  padding-top: 20px;
}

.song-request-form .form-body {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.song-request-form .form-body input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
}

.rsvp-card .save-status {
  margin-top: 10px;
}
