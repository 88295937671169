body {
  margin: 0;
  font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black;
  color: white; */

  --color-green-1: #A3B18A;
  --color-green-2: #588157;
  --color-green-3: #3A5A40;
  --color-green-4: #344E41;

  --color-light: #FFFFFF;
  --color-lighter: #FCF2ED;
  --color-darker: #EFDCD1;
  --color-dark: #333333;

  background-color: var(--color-lighter);
  color: #000;
}

body.dark {
  background-color: var(--color-green-4);
  color: var(--color-light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  /* color: lightblue; */
}

a:visited {
  /* color: lightblue; */
}

* {
  box-sizing: border-box;
}
