.header {
  background-color: var(--color-lighter);
  padding: 20px 10px;
  text-align: center;
  margin-bottom: 20px;
}

.header .title {
  font-family: 'Monsieur La Doulaise';
  font-size: 66px;
}

.header .title a {
  color: var(--color-dark);
  text-decoration: none;
}

.header .title .and {
  display: inline-block;
  padding: 0 30px;
}

.header .links {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.header .links a {
  padding: 10px 20px;
  color: var(--color-dark);
  text-decoration: none;
  font-size: 18px;
  border-bottom: 2px solid transparent;
  transition: border 0.2s ease-in-out;
  white-space: nowrap;
}

.header .links a:hover, .header .links a.active {
  border-bottom: 2px solid var(--color-green-1);
}
