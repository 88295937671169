.webcam {
  /* height: 85vh;
  margin: 20px auto;
  display: block; */
  /* width: 1280px; */
  position: fixed;
  top: 0;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 100%;
}

.top-controls .media-device-select {
  display: none;
}

.top-controls:hover .media-device-select {
  display: block;
}

.see-photos-header {
  text-align: center;
  padding-top: 20px;
}

.media-device-select {
  text-align: center;
  padding-top: 20px;
}

.photoResult {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0;
}

.photoResult .modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  padding: 10px;
  z-index: 10;
}

img.last-photo {
  max-width: 100%;
  max-height: 100%;
}

.photoResult .closeResult {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.photoResult .modal h2 {
  margin: 10px;
}

.photoResult .modal .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.buttons {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.snapButton {
  width: 80px;
  height: 80px;
  background-color: var(--color-green-1);
  color: var(--color-dark);
  padding: 10px;
  border-radius: 50%;
  font-size: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.snapButton:first-child {
  margin-right: 15px;
}

.snapButton img {
  width: 100%;
}
