.grid {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.grid img {
  max-width: 300px;
  padding: 10px;
  cursor: pointer;
}

.photos-header {
  padding: 20px 10px;
  text-align: center;
}
