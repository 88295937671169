.add-edit-invitation-form-fields {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

.invitation-name-input {
  min-width: 300px;
  margin-right: 10px;
  flex-grow: 1;
}

.invitations-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invitations-table .name-cell {
  max-width: 200px;
}

.invitations-table tr th {
  text-align: left;
  border-spacing: 0;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-dark);
}

.invitations-table tr:nth-child(odd) td {
  background-color: var(--color-darker);
}

.invitations-table tr:hover td {
  background-color: var(--color-light);
}

.invitations-table tr td {
  padding: 5px 10px;
  border-spacing: 0;
}

.invitation-count-input {
  min-width: 300px;
  margin-right: 10px;
}

.add-edit-invitation, .meal-list-item {
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--color-green-4);
}

.meal-list-item strong {
  font-size: 18px;
}

.bulk-invite-input {
  width: 100%;

}
