.accomodations-page {
}

.accomodations-page h1 {
  font-family: 'Italiana';
  margin-top: 0;
}

.accomodations-page .activity {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}

.accomodations-page .activity .image {
  max-width: 400px;
  padding-right: 20px;
}

.accomodations-page .activity .text h4 {
  margin-top: 0;
}

.accomodations-page .activity .text .link {
  margin-right: 10px;
}

.accomodations-page .activity.right {
  flex-direction: row-reverse;
}

.accomodations-page .activity.right .image {
  padding-right: 0;
  padding-left: 20px;
}

.accomodations-page .activity.right .text {
  text-align: right;
}

@media screen and (max-width: 750px) {
  .accomodations-page .activity, .accomodations-page .activity.right {
    flex-direction: column;
  }

  .accomodations-page .activity .image, .accomodations-page .activity.right .image {
    max-width: 100%;
    padding: 0;
  }

  .accomodations-page .activity.right .text {
    text-align: left;
  }

  .accomodations-page .activity .text h4 {
    margin-top: 1.33em;
  }
}
