.home-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  /* background-image: url('https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/IMG_1205.JPG');
  background-size: cover;
  background-position: center; */
}

.home-page .scroll-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.home-page .scroll-container .content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
}

.home-page .scroll-container .content .header {
  flex-grow: 0;
}

.home-page .scroll-container .content .divider {
  flex-grow: 0;
  /* height: 1px; */
  width: 100%;
  margin-top: 0;
}

.home-page .scroll-container .content .big-picture {
  flex-grow: 1;
  background-color: var(--color-green-4);
  background-image: url('https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/IMG_1205_1920.JPG');
  background-size: cover;
  background-position: center;
  min-height: 500px;
  margin: 0 10% 5% 10%;
  position: relative;
}

.home-page .scroll-container .content .big-picture .text-container {
  position: absolute;
  left: 5%;
  bottom: 5%;
  padding: 2%;
  color: var(--color-light);
  background-color: rgba(0,0,0,0.25);
  border-radius: 5px;
}

.home-page .scroll-container .content .big-picture .text-container .title {
  font-family: 'Italiana';
  font-size: 48px;
  font-weight: bold;
}

.home-page .scroll-container .content .big-picture .text-container .date {
  font-size: 26px;
  margin-top: 40px;
}

.home-page .scroll-container .content .big-picture .text-container .rsvp-link {
  font-weight: bold;
  font-size: 28px;
  padding: 18px 30px 15px 30px;
  background-color: var(--color-lighter);
  color: var(--color-dark);
  text-decoration: none;
  margin-top: 40px;
  display: inline-block;
  transition: transform 0.1s ease-in-out;
}

.home-page .scroll-container .content .big-picture .text-container .rsvp-link:hover {
  transform: scale(1.1);
}
