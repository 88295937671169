.invitation-login {
  max-width: 500px;
  margin: 50px auto;
  text-align: center;
}

.invitation-login .shay-hanna {
  margin: 20px 0;
  position: relative;
}

.invitation-login .shay-hanna .text {
  font-family: 'Monsieur La Doulaise';
  font-size: 82px;
  text-align: center;
  z-index: 1;
  position: relative;
}

.invitation-login .shay-hanna .image-left {
  position: absolute;
  top: calc(50% - 50px);
  left: -100px;
  transform: translate(-50%, -50%);
  width: 1000px;
}

.invitation-login .shay-hanna .image-right {
  position: absolute;
  top: calc(50% - 50px);
  right: -100px;
  transform: translate(50%, -50%) scaleX(-1);
  width: 1000px;
}

.invitation-login form {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
}

.invitation-login form input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
}
