.add-edit-meal-form-fields {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.meal-name-input {
  min-width: 300px;
  margin-right: 10px;
}

.meal-description-input {
  flex-grow: 1;
  min-width: 300px;
  margin-right: 10px;
}

.add-edit-meal, .meal-list-item {
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--color-green-4);
}

.meal-list-item strong {
  font-size: 18px;
}
