.venue-schedule-page {
}

.venue-schedule-page .address {
  font-size: 36px;
  text-align: center;
  font-family: 'Italiana';
  font-weight: bold;
  letter-spacing: 2px;
}

.venue-schedule-page h2 {
  font-family: 'Italiana';
}

.venue-schedule-page .schedule-of-events-table th {
  text-align: right;
}
