.App {

}

input[type="text"], input[type="password"], input[type="number"], textarea {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--color-dark);
  background-color: var(--color-lighter);
  font-family: 'Josefin Sans', sans-serif;
}

input:focus[type="text"], input:focus[type="password"], input:focus[type="password"], textarea:focus {
  border: 1px solid var(--color-green-4);
  background-color: var(--color-light);
  outline: none;
}

input[type="submit"], button, .link {
  padding: 10px;
  background-color: var(--color-green-3);
  color: var(--color-light);
  cursor: pointer;
  outline: none;
  border: 1px solid var(--color-dark);
  border-radius: 3px;
  font-family: 'Josefin Sans', sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

input:hover[type="submit"], button:hover, .link:hover {
  background-color: var(--color-green-2);
}

body.dark input[type="submit"], body.dark button, body.dark .link {
  background-color: var(--color-green-1);
  color: var(--color-dark);
}

body.dark input:hover[type="submit"], body.dark button:hover, body.dark .link:hover {
  background-color: var(--color-darker);
}

button {
  margin-right: 5px;
}

textarea {
  resize: vertical;
}

hr {
  border: none;
  height: 1px;
  background-color: var(--color-dark);
}

.page-default {
  max-width: 800px;
  margin: 0 auto 50px auto;
  padding: 40px 20px;
  background-color: var(--color-green-1);
  border-radius: 5px;
}
